import axios from "axios";
import { getDomainName } from "utils/utils";

const domain = getDomainName(window.location.href);
const url = new URL(window.location.href);

const host = url.host;

localStorage.setItem("domainName", domain);

const ApiObject = {
  "bigul.dronaquant.com": "https://bigulapi.dronaquant.com",

  "arjunv2.dronaquant.com": "https://arjunv2api.dronaquant.com",

  "uat.dronaquant.com": "https://uatapi.dronaquant.com",
  "localhost:3000": "https://uatapi.dronaquant.com",
  "localhost:3001": "https://uatapi.dronaquant.com",
  "stratxdrona.keev.tech": "https://uatapi.dronaquant.com",

  "mofs.dronaquant.com": " https://mofsapi.dronaquant.com/",
  "broker.dronaquant.com": "https://brokerapi.dronaquant.com/",

  "keev.dronaquant.com": " https://keevapi.dronaquant.com",

  "dronaquant.dronaquant.com": "https://api.dronaquant.com",
  "192.168.92.166": "http://192.168.92.166:8080",
  "192.168.123.133": "http://192.168.123.133:8080",
  "lakshmi.dronaquant.com": "https://lakshmiapi.dronaquant.com",
};

const apiurl = ApiObject[host] || "https://api.dronaquant.com";

const instance = axios.create({
  baseURL: "",
  timeout: 10000,
});
instance.defaults.timeout = 250000;
instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Accept"] = "application/json";

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("accessToken");
    // 08045574091
    if (authToken) {
      config.headers["Authorization"] = "Bearer " + authToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 500) {
    } else if (error.response.status === 403) {
    } else if (error.response.status === 404) {
    } else if (error.response.status === 400) {
    } else if (error.response.status === 409) {
    } else if (error.response.status === 401) {
      // localStorage.clear();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  // Add caching headers to the request
  config.headers["Cache-Control"] = "max-age=3600"; // Cache data for 1 hour
  return config;
});
// export const request = (url, method = "GET", data, isBaseChange = false) => {
//   return instance({
//     url: url,
//     //...{baseURL : isBaseChange ? `${SERVER_URL}/tradingview/`:API_URL},
//     method: method,
//     data: data,
//   });
// };
export const request = (
  url,
  method = "GET",
  data,
  isBaseChange = false,
  params = {}
) => {
  return instance({
    url: url,
    method: method,
    data: data,
    params: params, // Include query parameters
  });
};

export default instance;
