import { RefreshToken } from "apis/auth";
import axios from "axios";
import { getDomainName } from "utils/utils";

const domain = getDomainName(window.location.href);
const url = new URL(window.location.href);

const host = url.host;
const prefix =  '/';






// localStorage.setItem("domainName", domain);

const ApiObject = {
  // "localhost:3000": "https://stratxapi.keev.tech",
  "localhost:3000": "https://uatapi.stratx.in",
  "localhost:3001": "https://uatstratxapi.keev.tech",
  "localhost:3002": "https://uatstratxapi.keev.tech",
  "stratxdrona.keev.tech": "https://uatapi.stratx.in",
  "stratxmanager.keev.tech": "",
"stratxmanagerpreprod.keev.tech":"https://stratxapi.keev.tech",
  "keev.co.in":"https://uatapi.stratx.in"
};

const apiurl = ApiObject[host];

const instance1 = axios.create({
  // baseURL: apiurl,
  baseURL:process.env.REACT_APP_STRATX_MANAGER_APL_BASE_URL,
  timeout: 10000,
});
instance1.defaults.timeout = 250000;
instance1.defaults.headers.common["Content-Type"] = "application/json";
instance1.defaults.headers.common["Accept"] = "application/json";

instance1.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("accessToken");
    // const expirytime= "11.pm"
    if (authToken) {
      config.headers["Authorization"] = "Bearer " + authToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance1.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 500) {
    } else if (error.response.status === 400) {
      // localStorage.clear();
      // window.location.href = "/";
    } else if (error.response.status === 404) {
      
    } else if (error.response.status === 409) {
    } else if (error.response.status === 401) {
      // return
      
      RefreshToken({ token: localStorage.getItem("refresh_token") })
        .then((res) => {
          localStorage.setItem("accessToken", res?.data[0]?.access_token);
          // Retry the original request
          // return instance1(error.config);
        })
        .catch((refreshError) => {
         
          localStorage.clear();

          window.location.href = prefix;
          // return Promise.reject(refreshError);
        });
    }
    return Promise.reject(error);
  }
);

instance1.interceptors.request.use((config) => {
  // Add caching headers to the request
  config.headers["Cache-Control"] = "max-age=3600"; // Cache data for 1 hour
  return config;
});
// export const request = (url, method = "GET", data, isBaseChange = false) => {
//   return instance({
//     url: url,
//     //...{baseURL : isBaseChange ? `${SERVER_URL}/tradingview/`:API_URL},
//     method: method,
//     data: data,
//   });
// };
export const request1 = (
  url,
  method = "GET",
  data,
  isBaseChange = false,
  params = {}
) => {
  return instance1({
    url: url,
    method: method,
    data: data,
    params: params, // Include query parameters
  });
};

export default instance1;
