import moment from "moment";
// import { isExpired } from "react-jwt";

export const isTokenExpired = (token) => {
  // const decoded = decodeToken(token);
  let expiry = localStorage.getItem("expiry") || moment().add("1", "day");
  // const myExpired = isExpired(token);
  if (moment(expiry) > moment()) {
    return true;
  } else {
    // const now = Date.now().valueOf() / 1000;
    // if (myExpired) {
    //   localStorage.clear();
    //   return true;
    // }
    // if (typeof decoded.exp !== "undefined" && decoded.exp > now) {
    //   return true;
    // }
    return false;
  }
};

export const getDomainName = (url) => {
  
  let domain = url
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
    .split("/")[0]
    .split(":")[0]
    .split(".")[0];
  return domain;
};

export const replaceSpacesWithUnderscores = (sentence) => 
  sentence.trim().replace(/\s+/g, '_');

export const generateFormattedString = (text, strategy = '', client = '') => {
  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, '') + 
                        `_${date.toTimeString().slice(0, 5).replace(':', '')}`;

  // Build the base string while skipping empty parts
  const baseString = [
    text,
    replaceSpacesWithUnderscores(strategy),
    replaceSpacesWithUnderscores(client)
  ]
    .filter((part) => part) // Remove empty strings
    .join('_');

  return `${baseString}_${formattedDate}`
};